$(document).ready(function () {
  if ($(".js-promo-section").length) {
    handlePromoSection();
  }
});

let handlePromoSection: () => null;

handlePromoSection = () => {
  const ACTIVE_CHANGE_DELAY = 3000;
  const maxIndex = getMaxIndex();

  // stop here if there are zero or only one item
  if (maxIndex == 0) {
    return null;
  }

  let isLooping: boolean = true;
  let currentIndex: number = 0;

  setTimeout(function () {
    ticker(), ACTIVE_CHANGE_DELAY;
  });

  function ticker() {
    /* move to next section if looping */
    if (isLooping === true) {
      moveToNextSection();
    }
    setTimeout(function () {
      ticker();
    }, ACTIVE_CHANGE_DELAY);
  }

  function moveToNextSection() {
    const nextIndex: number = currentIndex < maxIndex ? currentIndex + 1 : 0;
    setActiveItem(nextIndex);
  }

  $(".js-promo-section-item").mouseover(function () {
    const index = $(this).data("promo-index");
    stopLooping();
    setActiveItem(index);
  });

  $(".js-promo-section-item").focus(function () {
    const index = $(this).data("promo-index");
    stopLooping();
    setActiveItem(index);
  });

  $(".js-promo-section").mouseleave(function () {
    restartLooping();
  });

  $(".js-promo-section").focusout(function () {
    restartLooping();
  });

  function getMaxIndex() {
    let maxIndex = 0;

    $(".js-promo-section-item").each(function () {
      maxIndex = $(this).data("promo-index");
    });

    return maxIndex;
  }

  function setActiveItem(index: number) {
    currentIndex = index;

    $(".js-promo-section")
      .children()
      .each(function () {
        if ($(this).data("promo-index") == currentIndex) {
          $(this).addClass("active");
        } else {
          $(this).removeClass("active");
        }
      });
  }

  function stopLooping() {
    isLooping = false;
  }

  function restartLooping() {
    isLooping = true;
  }
};
